import React, { Component, useRef } from "react";
import { Link, NavLink } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import userAvatar from "../assets/img/icons.jpg";

import { useSelector } from "react-redux";

export default function Sidebar() {
    const scrollBarRef = useRef();
    const user = useSelector(state => state.user);

    const toggleFooterMenu = (e) => {
        e.preventDefault();

        let parent = e.target.closest(".sidebar");
        parent.classList.toggle("footer-menu-show");
    }

    console.log("user", user);

    return (
        <div className="sidebar">
            <div className="sidebar-header">
                <Link to="/" className="sidebar-logo">
                    <img src={require('../assets/images/logo.jpg')} />
                </Link>
            </div>
            <PerfectScrollbar className="sidebar-body" ref={scrollBarRef}>
                <SidebarMenu onUpdateSize={() => scrollBarRef.current?.updateScroll()} />
            </PerfectScrollbar>
            <div className="sidebar-footer">
                <div className="sidebar-footer-top">
                    <div className="sidebar-footer-thumb">
                        <img src={userAvatar} alt="" />
                    </div>
                    <div className="sidebar-footer-body">
                        <h6><Link onClick={toggleFooterMenu} to="" >Unnati Skils Admin</Link></h6>
                        <p>{user?.role}</p>
                    </div>
                    <Link onClick={toggleFooterMenu} to="" className="dropdown-link"><b><i className="ri-arrow-down-s-line"></i></b></Link>
                </div>
                <div className="sidebar-footer-menu">
                    <nav className="nav">
                    </nav>
                    <hr />
                    <nav className="nav">
                        <Link to="/logout"><i className="ri-logout-box-r-line"></i> Log Out</Link>
                    </nav>
                </div>
            </div>
        </div>
    )
}

class SidebarMenu extends Component {
    populateMenu = (m) => {
        const menu = m.map((m, key) => {
            let sm;
            if (m.submenu) {
                sm = m.submenu.map((sm, key) => {
                    return (
                        <NavLink to={sm.link} className="nav-sub-link" style={{ userSelect: 'none' }} key={key}>{sm.label}</NavLink>
                    )
                })
            }

            return (
                <li key={key} className="nav-item">
                    {(!sm) ? (
                        <NavLink to={m.link} className="nav-link"><i className={m.icon} style={{ fontSize: 12, paddingRight: 6 }}></i> <span style={{ userSelect: 'none' }}>{m.label}</span></NavLink>
                    ) : (
                        <div onClick={this.toggleSubMenu} className="nav-link has-sub"><i className={m.icon}></i> <span style={{ userSelect: 'none', fontSize: 12 }}>{m.label}</span></div>
                    )}
                    {m.submenu && <nav className="nav nav-sub">{sm}</nav>}
                </li>
            )
        });
        return (
            <ul className="nav nav-sidebar">
                {menu}
            </ul>
        );
    }

    // Toggle menu group
    toggleMenu = (e) => {
        e.preventDefault();

        let parent = e.target.closest('.nav-group');
        parent.classList.toggle('show');

        this.props.onUpdateSize();
    }

    // Toggle submenu while closing siblings' submenu
    toggleSubMenu = (e) => {
        e.preventDefault();

        let parent = e.target.closest('.nav-item');
        let node = parent.parentNode.firstChild;

        while (node) {
            if (node !== parent && node.nodeType === Node.ELEMENT_NODE)
                node.classList.remove('show');
            node = node.nextElementSibling || node.nextSibling;
        }

        parent.classList.toggle('show');

        this.props.onUpdateSize();
    }

    render() {
        return (
            <React.Fragment>
                <div className="nav-group show">
                    <ul className="nav nav-sidebar py-0">
                        <li className="nav-item">
                            <NavLink to="" className="nav-link"><i className={"ri-home-3-line"}></i> <span style={{ userSelect: 'none' }}>Dashboard</span></NavLink>
                        </li>
                    </ul>
                </div>
                <div className="nav-group">
                    <div className="nav-label" onClick={this.toggleMenu}><i className={"fa fa-book"} style={{ fontSize: 16, marginRight: 10 }}></i><span style={{ userSelect: 'none', fontSize: 14 }}>Course Management</span></div>
                    {this.populateMenu([
                        {
                            "label": "Category",
                            "link": "/course-category",
                            "icon": "fa fa-shapes",
                        },
                        {
                            "label": "Coupons",
                            "link": "/course-coupon",
                            "icon": "fa fa-credit-card-alt"
                        },
                        {
                            "label": "Courses",
                            "link": "/courses",
                            "icon": "fa fa-book"
                        },
                    ])}
                </div>
                {/* <div className="nav-group">
                    <div className="nav-label" onClick={this.toggleMenu}><i className={"fa fa-book"} style={{ fontSize: 16, marginRight: 10 }}></i><span style={{ userSelect: 'none', fontSize: 14 }}>Course Enrolments</span></div>
                    {this.populateMenu([
                        {
                            "label": "All Enrolments",
                            "link": "/category",
                            "icon": "fa fa-shapes",
                        },
                        {
                            "label": "Completed Enrolments",
                            "link": "/shopping_product",
                            "icon": "fa fa-product-hunt"
                        },
                        {
                            "label": "Pending Enrolments",
                            "link": "/shopping_product",
                            "icon": "fa fa-product-hunt"
                        },
                        {
                            "label": "Rejected Enrolments",
                            "link": "/shopping_product",
                            "icon": "fa fa-product-hunt"
                        },
                        {
                            "label": "Report",
                            "link": "/shopping_product",
                            "icon": "fa fa-product-hunt"
                        },
                    ])}
                </div> */}
                <div className="nav-group">
                    <div className="nav-label" onClick={this.toggleMenu}><i className={"fa fa-users"} style={{ fontSize: 16, marginRight: 10 }}></i><span style={{ userSelect: 'none', fontSize: 14 }}>User Management</span></div>
                    {this.populateMenu([
                        {
                            "label": "Registered Users",
                            "link": "/user-registered",
                            "icon": "fa fa-users",
                        },
                        {
                            "label": "Subscribers",
                            "link": "/user-subscriber",
                            "icon": "fa fa-user"
                        },
                    ])}
                </div>
                <div className="nav-group show">
                    <ul className="nav nav-sidebar py-0">
                        <li className="nav-item">
                            <NavLink to="/contact" className="nav-link"><i className={"fa fa-user-circle"} style={{ fontSize: 16 }}></i> <span style={{ userSelect: 'none' }}></span>Contact Us</NavLink>
                        </li>
                    </ul>
                </div>
                <div className="nav-group show">
                    <ul className="nav nav-sidebar py-0">
                        <li className="nav-item">
                            <NavLink to="/user-enrolment" className="nav-link"><i className={"fa fa-envelope"} style={{ fontSize: 16 }}></i> <span style={{ userSelect: 'none' }}></span>User Enrolment</NavLink>
                        </li>
                    </ul>
                </div>
                <div className="nav-group show">
                    <ul className="nav nav-sidebar py-0">
                        <li className="nav-item">
                            <NavLink to="/our-teams" className="nav-link"><i className={"fa fa-envelope"} style={{ fontSize: 16 }}></i> <span style={{ userSelect: 'none' }}></span>Team</NavLink>
                        </li>
                    </ul>
                </div>
                {/*  <div className="nav-group show">
                    <ul className="nav nav-sidebar py-0">
                        <li className="nav-item">
                            <NavLink to="/subCategory" className="nav-link"><i className={"fa fa-user-circle"} style={{ fontSize: 16 }}></i> <span style={{ userSelect: 'none' }}>SUB CATEGORY</span></NavLink>
                        </li>
                    </ul>
                </div>
                <div className="nav-group show">
                    <ul className="nav nav-sidebar py-0">
                        <li className="nav-item">
                            <NavLink to="/petCategory" className="nav-link"><i className={"fa fa-cog"} style={{ fontSize: 16 }}></i> <span style={{ userSelect: 'none' }}>PET CATEGORY</span></NavLink>
                        </li>
                    </ul>
                </div>
                <div className="nav-group show">
                    <ul className="nav nav-sidebar py-0">
                        <li className="nav-item">
                            <NavLink to="/recommended" className="nav-link"><i className={"fa fa-file-text"} style={{ fontSize: 16 }}></i> <span style={{ userSelect: 'none' }}>RECOMMENDED</span></NavLink>
                        </li>
                    </ul>
                </div>
                <div className="nav-group show">
                    <ul className="nav nav-sidebar py-0">
                        <li className="nav-item">
                            <NavLink to="/registered_pet" className="nav-link"><i className={"fa fa-cog"} style={{ fontSize: 16 }}></i> <span style={{ userSelect: 'none' }}>REGISTERED PET</span></NavLink>
                        </li>
                    </ul>
                </div>
               <div className="nav-group show">
                    <ul className="nav nav-sidebar py-0">
                        <li className="nav-item">
                            <NavLink to="/registered" className="nav-link"><i className={"fa fa-registered"} style={{ fontSize: 16 }}></i> <span style={{ userSelect: 'none' }}>REGISTERED USER</span></NavLink>
                        </li>
                    </ul>
                </div>

               <div className="nav-group show">
                    <ul className="nav nav-sidebar py-0">
                        <li className="nav-item">
                            <NavLink to="/feedback" className="nav-link"><i className={"fa fa-comments"} style={{ fontSize: 16 }}></i> <span style={{ userSelect: 'none' }}>FEEDBACK</span></NavLink>
                        </li>
                    </ul>
                </div> */}
            </React.Fragment >
        )
    }
}

window.addEventListener("click", function (e) {
    // Close sidebar footer menu when clicked outside of it
    let tar = e.target;
    let sidebar = document.querySelector(".sidebar");
    if (!tar.closest(".sidebar-footer") && sidebar) {
        sidebar.classList.remove("footer-menu-show");
    }

    // Hide sidebar offset when clicked outside of sidebar
    if (!tar.closest(".sidebar") && !tar.closest(".menu-link")) {
        document.querySelector("body").classList.remove("sidebar-show");
    }
});

window.addEventListener("load", function () {
    let skinMode = localStorage.getItem("sidebar-skin");
    let HTMLTag = document.querySelector("html");

    if (skinMode) {
        HTMLTag.setAttribute("data-sidebar", skinMode);
    }
});