import React, { useEffect, useState, useRef } from 'react'
import Header from '../../layouts/Header'
import { Card, CardBody, CardSubtitle, CardTitle, Row, Col, Modal, ModalBody } from 'reactstrap'
import { Button } from 'react-bootstrap';
import { AvForm, AvField } from "availity-reactstrap-validation";
import { del, get, post, put, upload } from '../../helper/api_helper';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import DataTable from 'react-data-table-component';
import ConfirmModal from '../../components/ConfirmModal';
import * as Utils from "../../Utils";
import AddForm from './AddProduct';
import Footer from '../../layouts/Footer';
import Loader from '../../layouts/Loader';

function Product() {

    const user = useSelector(state => state.user);
    const [isAdd, setIsAdd] = useState(false);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [columns, setColumns] = useState([]);
    const [currentData, setCurrentData] = useState(null);
    const [confirm, setConfirm] = useState(false);


    useEffect(() => {
        if (user?.token) {
            getData();
        }
    }, [user]);

    useEffect(() => {
        setColumns([
            {
                name: "Thumb",
                cell: (row) => <>
                    <img src={row?.thumb} style={{ width: 70, height: 70, padding: 10 }} />
                </>,
                maxWidth: '180px',
                minWidth: '100px'
            },
            {
                name: "Image",
                cell: (row) => <>
                    <img src={row?.images?.length ? row?.images[0] : ""} style={{ width: 60 }} />
                </>,
                maxWidth: '180px',
                minWidth: '100px'
            },
            {
                name: 'Category',
                selector: row => row?.cat,
                // maxWidth:'200px'
            },
            {
                name: 'Name',
                selector: row => row.name,
                // maxWidth:'200px'
            },
            {
                name: 'Price',
                selector: row => row.price,
            },
            {
                name: 'Off Price',
                selector: row => row.offprice,
            },
            {
                cell: (row) => <>
                    <Button onClick={() => handleUpdateStatus(row)}
                        title={row?.isActive ? "Inactive" : "Active"}
                        className={`btn_status ${row?.isActive && 'active'}`}
                    >
                        <span className="text-white" style={{}}>
                            {!row?.isActive ? 'Inactive' : 'Active'}
                        </span>
                    </Button>
                </>,
                name: 'Status',
                ignoreRowClick: true,
                maxWidth: '120px'
            },
            {
                cell: (row) => <>
                    <button onClick={() => handleUpdateProj(row)}
                        title={"Edit"}
                        style={{ marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none', backgroundColor: Utils.themeColor, color: 'white', borderRadius: 3 }}><span className="ri-edit-fill" style={{ fontSize: 15 }}></span></button>
                    <button onClick={() => handleDeleteProj(row)}
                        title={"Delete"}
                        style={{ marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none', backgroundColor: Utils.themeColor, color: 'white', borderRadius: 3 }}><span className="ri-delete-bin-6-line" style={{ fontSize: 15 }}></span></button>
                </>,
                name: 'Action',
                ignoreRowClick: true,
                maxWidth: '130px'
            },
        ])
    }, [data]);

    const getData = () => {
        if (!loading) {
            setLoading(true);
            get("course", { token: user?.token })
                .then(res => {
                    setLoading(false);
                    if (res?.statusCode == 200) {
                        setData(res?.data);
                    }
                })
                .catch(err => {
                    setLoading(false);
                    toast.error("Something Went Wrong!");
                })
        }
    }


    const handleUpdateStatus = (row) => {
        setCurrentData({ ...row, actionType: "Status" });
        setConfirm(true);
    }

    const onConfirm = () => {
        if (!loading) {
            setLoading(true);
            let body = {
                courseId: currentData?._id,
                token: user?.token
            }
            if (currentData?.actionType == 'Status') {
                body = { ...body, isActive: !currentData?.isActive }
                put("course", body)
                    .then(res => {
                        setLoading(false);
                        if (res?.statusCode == 200) {
                            setConfirm(false);
                            toast.success(res?.message);
                            getData()
                        } else
                            toast.error("" + res?.error);
                    })
                    .catch(err => {
                        setLoading(false);
                        console.error("error while updating data", err);
                        toast.error("Something Went Wrong!");
                    })
            }
            if (currentData?.actionType == 'Delete') {
                del("course", body)
                    .then(res => {
                        setLoading(false);
                        if (res?.statusCode == 200) {
                            setConfirm(false);
                            toast.success(res?.message);
                            getData()
                        } else
                            toast.error("" + res?.error);
                    })
                    .catch(err => {
                        setLoading(false);
                        console.error("error while updating data", err);
                        toast.error("Something Went Wrong!");
                    })
            }
        }
    }

    const handleDeleteProj = (row) => {
        setCurrentData({ ...row, actionType: "Delete" });
        setConfirm(true);
    }

    const handleUpdateProj = (row) => {
        setCurrentData(row);
        setIsAdd(true);
    }

    const ExpandedComponent = ({ data }) =>
        <div className='expending_box'>
            <Row>
                <Col lg={6}>
                    <div className='expend_detail'>
                        <h4>Description</h4>
                    </div>
                </Col>
                <Col lg={6}>
                    <div className='expend_detail'>
                        <p>{data?.desc}</p>
                    </div>
                </Col>
            </Row>
        </div>

    return (
        <React.Fragment>
            <Header />
            <ConfirmModal
                show={confirm}
                onConfirm={onConfirm}
                onCloseClick={() => setConfirm(false)}
                data={currentData}
            />
            {/* {
                loading == true && <Loader />
            } */}
            {!isAdd ?
                <div className="main main-app p-3 p-lg-4">
                    <Card>
                        <Row className='mb-4'>
                            <Col md={11}>
                                <CardBody>
                                    <CardTitle><b>Course</b></CardTitle>
                                </CardBody>
                            </Col>
                            <Col md={1}>
                                <div className='action-btn'>
                                    <button type="button" style={{ background: Utils.themeColor, color: 'white' }} className="btn btn-add" onClick={() => { setCurrentData(null); setIsAdd(true) }}><i className={'ri-add-fill'} /></button>
                                </div>
                            </Col>
                        </Row>
                        <DataTable
                            columns={columns}
                            data={data}
                            pagination
                            expandableRows={true}
                            expandOnRowClicked={false}
                            expandableRowDisabled={row => row.disabled}
                            expandableRowsComponent={ExpandedComponent}
                            expandableRowsComponentProps={row => row.Action}
                            conditionalRowStyles={[{
                                when: row => row?.style,
                                style: row => ({ width: row?.style?.width }),
                            },
                            ]}
                            customStyles={{
                                headCells: {
                                    style: {
                                        color: 'black',
                                        fontWeight: 'bold',
                                        fontSize: 15,
                                        width: 0
                                    },
                                },
                                cells: {
                                    style: {
                                        width: 0
                                    }
                                }
                            }}
                        />
                    </Card>
                    <Footer />
                </div>
                :
                <AddForm
                    user={user}
                    setIsAdd={setIsAdd}
                    loading={loading}
                    setLoading={setLoading}
                    getData={getData}
                    currentData={currentData}
                />
            }
        </React.Fragment>
    )
}

export default Product;