import React from "react";

// Dashboard
import FinanceMonitoring from "../dashboard/FinanceMonitoring";
import ClientLocation from '../pages/ClientLocation';
import SpecialOffer from '../pages/SpecialOffer';
import Product from '../pages/Product';
import Employee from '../pages/Employee';
import GalleryCat from '../pages/GalleryCat';
import Gallery from '../pages/Gallery';
import Contact from "../pages/Contact";

import CourseCategory from '../pages/CourseManagement/Category';
import CourseCoupons from '../pages/CourseManagement/Coupons';
import Course from '../pages//CourseManagement';
import Subscriber from "../pages/User Management/subscriber";
import Registered from "../pages/User Management/Registered";
import Enrolment from '../pages/Entrolment';
import Teams from '../pages/Team';


const protectedRoutes = [
  { path: "/", element: <FinanceMonitoring /> },
  // { path: "/client-location", element: <ClientLocation /> },
  // { path: "/special-offer", element: <SpecialOffer /> },
  // { path: "/product", element: <Product /> },
  // { path: "/employee", element: <Employee /> },
  // { path: "/category", element: <GalleryCat /> },
  // { path: "/gallery", element: <Gallery /> },
  { path: "/contact", element: <Contact /> },
  { path: "/user-registered", element: <Registered /> },
  { path: "/user-enrolment", element: <Enrolment /> },
  // { path: "/category", element: <Category /> },
  // { path: "/subCategory", element: <SubCategory /> },
  // { path: "/petCategory", element: <PetCategory />},
  // { path: "/recommended", element: <Recommended />},
  // { path: "/registered_pet", element: <RegisteredPet />},
  // { path: "/feedback", element: <Feedback />},
  // { path: "/registered", element: <Registered />},


  { path: "/course-category", element: <CourseCategory /> },
  { path: "/course-coupon", element: <CourseCoupons /> },
  { path: "/courses", element: <Course /> },
  { path: "/user-subscriber", element: <Subscriber /> },
  { path: "/our-teams", element: <Teams /> },


]

export default protectedRoutes;