import React, { useEffect, useState, useRef } from 'react';
import { toast } from 'react-toastify';
import { Row, Col, Card, CardBody, CardTitle, Modal, ModalBody, Label } from 'reactstrap';
import { del, get, post, put, upload } from '../../helper/api_helper';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import Resizer from "react-image-file-resizer";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import * as Utils from "../../Utils";
import Multiselect from 'multiselect-react-dropdown';

function AddProduct(props) {
    const { setIsAdd, user, currentData, loading, setLoading, getData } = props;
    const [file, setFile] = useState(null);
    const [banners, setBanners] = useState([]);
    const [cropperModal, setCropperModal] = useState(false);
    const [thumb, setThumb] = useState(null);
    const [cat, setCat] = useState()
    const [fileType, setFileType] = useState("thumb");
    const imagePickerRef = useRef();
    const cropperRef = useRef();

    useEffect(() => {
        if (currentData) {
            setBanners(currentData?.images);
            setThumb(currentData?.thumb);
        }
    }, [currentData]);

    useEffect(() => {
        getCat();
    }, [])

    const getCat = () => {
        get("course/cat", { token: user?.token })
            .then(res => {
                if (res?.statusCode == 200) {
                    setCat(res?.data);
                }
            })
            .catch(err => {
                toast.error("Something Went Wrong!");
            });
    }

    console.log("get coruse category", cat);

    const handleValidSubmit = async (e, v) => {
        if (!loading) {
            if (banners?.length < 1) {
                toast.error(`Please select atleast one banner Image!`);
                return;
            }
            if (!thumb) {
                toast.error(`Please select a Thumb Image!`);
                return;
            }
            setLoading(true);
            let body = {
                ...v,
                token: user?.token
            }
            let temp = [];
            await Promise.all(banners.map(async (item) => {
                if (item?.substring(0, 4) == 'data') {
                    const obj = await urltoFile(item, new Date().getTime() + '.webp', 'image/webp');
                    const tempObj = await resizeFile(obj);
                    const form = new FormData();
                    form.append("image", tempObj);
                    const uploadRes = await upload("course/image_upload", form);
                    if (uploadRes?.statusCode == 200)
                        temp.push(uploadRes.data);
                }
                else {
                    temp.push(item);
                }
            }));
            if (thumb?.substring(0, 4) == 'data') {
                const obj = await urltoFile(thumb, new Date().getTime() + '.webp', 'image/webp');
                const form = new FormData();
                form.append("image", obj);
                const uploadRes = await upload("course/image_upload", form);
                if (uploadRes?.statusCode == 200)
                    body = { ...body, thumb: uploadRes?.data };
            }
            body = { ...body, images: temp };
            console.log("check body for couruse", body);

            if (currentData?._id) {
                body = { ...body, courseId: currentData?._id }
                console.log('body to submit', body);
                put("course", body)
                    .then(res => {
                        setLoading(false);
                        if (res?.statusCode == 200) {
                            toast.success(res?.message);
                            setIsAdd(false);
                            getData()
                        } else
                            toast.error("" + res?.error);
                    })
                    .catch(err => {
                        setLoading(false);
                        console.error("error while updating data", err);
                        toast.error("Something Went Wrong!");
                    })
            } else {
                // return;
                post("course", body)
                    .then(res => {
                        setLoading(false);
                        if (res?.statusCode == 200) {
                            toast.success(res?.message);
                            setIsAdd(false);
                            getData();
                        } else {
                            toast.error(res?.error);
                        }
                    })
                    .catch(err => {
                        setLoading(false);
                        console.error("Error while adding city", err);
                        toast.error("Something Went Wrong!");
                    })
            }
        }
    }

    const urltoFile = (url, filename, mimeType) => {
        return (fetch(url)
            .then(function (res) { return res.arrayBuffer(); })
            .then(function (buf) { return new File([buf], filename, { type: mimeType }); })
        );
    }

    const resizeFile = (file) =>
        new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                1800,
                600,
                "webp",
                100,
                0,
                (uri) => {
                    resolve(uri);
                },
                "file"
            );
        });

    const handleImgChange = (file) => {
        setFile(URL.createObjectURL(file));
        setCropperModal(true);
    }

    const cropImage = () => {
        setLoading(true);
        const fileData = cropperRef?.current?.cropper?.getCroppedCanvas().toDataURL();
        if (fileType == "banner") {
            let temp = Object.assign([], banners);
            temp.push(fileData);
            setBanners(temp);
        } else {
            setThumb(fileData);
        }
        setCropperModal(false);
        setLoading(false);
    }

    const handleRemoveBanner = (i) => {
        let temp = Object.assign([], banners);
        temp.splice(i, 1);
        setBanners(temp);
    }

    return (
        <React.Fragment>
            <div className="main main-app p-3 p-lg-4">
                <Modal isOpen={cropperModal} centered={true}>
                    <ModalBody>
                        <h5 className="text-black font-size-20">Crop Image</h5>
                        <div className="p-2">
                            <AvForm
                                className="form-horizontal"
                                onValidSubmit={(e, v) => {
                                    cropImage()
                                }}
                            >
                                <div className="mb-3 mt-2">
                                    <Cropper
                                        style={{ height: 200, width: 400 }}
                                        aspectRatio={fileType == 'banner' ? '' / '' : 1 / 1}
                                        preview=".img-preview"
                                        guides={true}
                                        src={file}
                                        ref={cropperRef}
                                    />
                                </div>
                                <div className="mt-4">
                                    <Row>
                                        <Col md={6}>
                                            <button
                                                style={{ border: 'none', backgroundColor: Utils.themeColor, color: 'white' }}
                                                className="btn w-100 waves-effect waves-light"
                                                onClick={() => { setCropperModal(false); setFile(null) }}
                                                type="reset"
                                            >
                                                Cancel
                                            </button>
                                        </Col>
                                        <Col md={6}>
                                            <button
                                                className="btn w-100 waves-effect waves-light"
                                                type="submit"
                                                style={{ border: 'none', backgroundColor: Utils.themeColor, color: 'white' }}
                                            >
                                                Submit
                                            </button>
                                        </Col>
                                    </Row>
                                </div>
                            </AvForm>
                        </div>
                    </ModalBody>
                </Modal>
                <input
                    name="images"
                    label="image"
                    className="d-none"
                    type="file"
                    accept="image/*"
                    onChange={(e) => handleImgChange(e.target.files[0])}
                    ref={imagePickerRef}
                />
                <Card>
                    <CardBody>
                        <Row>
                            <Col md={10}>
                                <CardTitle className='mb-4'><b>{currentData?._id ? 'Update Course' : 'Add New Course'}</b></CardTitle>
                            </Col>
                            <Col>
                                <button type="button" onClick={() => setIsAdd(false)} className="btn btn-sign" style={{ float: 'right', color: 'white', backgroundColor: Utils.themeColor }}>Close</button>
                            </Col>
                        </Row>
                        <AvForm onValidSubmit={handleValidSubmit}>
                            <div className='mb-4'>
                                <Row>
                                    <Label>Thumb</Label>
                                    <Col md={12} className='mb-4'>
                                        {thumb ?
                                            <div className='img-sqr-100'>
                                                <img src={thumb} style={{ width: '100%', height: '100%' }} />
                                                <div className='btn_cross'>
                                                    <button type="button" style={{ backgroundColor: Utils.themeColor, color: 'white' }} className="btn btn-add" onClick={() => { setThumb(null); setFile(null); }}><i className={'fa fa-close'} /></button>
                                                </div>
                                            </div>
                                            :
                                            <Col md={4}>
                                                <div className='img-place-sqr-100'>
                                                    <div className='action-btn'>
                                                        <button type="button" className="btn btn-add" style={{ backgroundColor: Utils.themeColor, color: 'white' }} onClick={() => { setFileType("thumb"); imagePickerRef.current.click() }}><i className={'ri-image-fill'} /></button>
                                                    </div>
                                                </div>
                                            </Col>
                                        }
                                    </Col>
                                    <Label>Images</Label>
                                    {banners?.map((item, index) => (
                                        <Col md={4}>
                                            <div className='img-banner'>
                                                <img src={item} style={{ width: '100%', height: '100%' }} />
                                                <div className='btn_cross_banner'>
                                                    <button type="button" className="btn btn-add" style={{ backgroundColor: Utils.themeColor, color: 'white' }} onClick={() => handleRemoveBanner(index)}><i className={'fa fa-close'} /></button>
                                                </div>
                                            </div>
                                        </Col>
                                    ))}
                                    {banners.length < 5 &&
                                        <Col md={4}>
                                            <div className='img-place-banner'>
                                                <div className='action-btn'>
                                                    <button type="button" className="btn btn-add" style={{ backgroundColor: Utils.themeColor, color: 'white' }} onClick={() => { setFileType("banner"); imagePickerRef.current.click() }}><i className={'ri-image-fill'} /></button>
                                                </div>
                                            </div>
                                        </Col>
                                    }
                                </Row>
                            </div>
                            <Row>
                                <Col md={6}>
                                    <div className='mb-4'>
                                        <AvField
                                            name="name"
                                            label="Course Name*"
                                            value={currentData?.name}
                                            required
                                            type="text"
                                        >

                                        </AvField>
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className='mb-4'>
                                        <AvField
                                            name="catId"
                                            label="Category*"
                                            value={currentData?.catId}
                                            // required
                                            type="select"
                                        >
                                            <option value={""}>Select Category</option>
                                            {cat?.map((item) => (
                                                <option value={item?._id}>{item?.title}</option>
                                            ))}
                                        </AvField>
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className='mb-4'>
                                        <AvField
                                            name="price"
                                            label="Price*"
                                            value={currentData?.price}
                                        />
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className='mb-4'>
                                        <AvField
                                            name="offprice"
                                            label="Off Price*"
                                            value={currentData?.offprice}
                                        />
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className='mb-4'>
                                        <AvField
                                            name="consulting"
                                            label="Consulting"
                                            value={currentData?.consulting}
                                        />
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className='mb-4'>
                                        <AvField
                                            name="financial"
                                            label="Financial Advice"
                                            value={currentData?.financial}
                                        />
                                    </div>
                                </Col>
                                <Col md={12}>
                                    <div className='mb-4'>
                                        <AvField
                                            name="benefits"
                                            label="Investment And Benefits"
                                            value={currentData?.finencial}
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <div className='mb-4'>
                                <AvField
                                    name="desc"
                                    label=" Description*"
                                    placeholder="Enter description"
                                    value={currentData?.desc}
                                    required
                                    type="textarea"
                                    rows={6}
                                />
                            </div>
                            <Row>

                            </Row>
                            <Row className='mt-5'>
                                <Col md={6}>
                                    <button type="reset" onClick={() => setIsAdd(false)} className="btn btn-sign" style={{ float: 'right', color: 'white', background: Utils.themeColor }}>Cancel</button>
                                </Col>
                                <Col md={6}>
                                    <button type="submit" style={{ background: Utils.themeColor, color: 'white' }} className="btn btn-sign">{currentData?._id ? 'Update Course' : 'Add Course'}</button>
                                </Col>
                            </Row>
                        </AvForm>
                    </CardBody>
                </Card>
            </div>
        </React.Fragment>
    )
}

export default AddProduct;