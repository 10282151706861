import React, { useEffect, useState, useRef } from 'react'
import Header from '../../layouts/Header'
import { Card, CardBody, CardSubtitle, CardTitle, Row, Col, Modal, ModalBody } from 'reactstrap'
import { Button } from 'react-bootstrap';
import { AvForm, AvField } from "availity-reactstrap-validation";
import { del, get, post, put, upload } from '../../helper/api_helper';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import moment from 'moment';
import DataTable from 'react-data-table-component';
import ConfirmModal from '../../components/ConfirmModal';
import Footer from '../../layouts/Footer';
import Loader from '../../layouts/Loader';
import * as Utils from "../../Utils";


function AddService() {

    const user = useSelector(state => state.user);
    const [isAdd, setIsAdd] = useState(false);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [columns, setColumns] = useState([]);
    const [currentData, setCurrentData] = useState(null);
    const [confirm, setConfirm] = useState(false);
    const [file, setFile] = useState(null);
    const [date, setDate] = useState(new Date());
    const imagePickerRef = useRef();


    useEffect(() => {
        if (user?.token)
            getData();
    }, [user]);

    useEffect(() => {
        // console.log('-----', data)
        setColumns([
            {
                name: 'Sl.No',
                cell: (row, index) => <>
                    <span>{index + 1}</span>
                </>,
            },
            {
                name: 'Name',
                selector: row => row.name,
            },
            {
                name: 'Code',
                selector: row => row.code,
            },
            {
                name: 'Discount',
                selector: row => row.discount,
            },
            {
                name: 'Date',
                selector: row => moment(row?.createdAt).format("DD-MMM-YYYY"),
            },
            {
                cell: (row) => <>
                    <Button onClick={() => handleUpdateStatus(row)}
                        title={row?.isActive ? "Inactive" : "Active"}
                        className={`btn_status ${row?.isActive && 'active'}`}
                    >
                        <span className="text-white" style={{}}>
                            {!row?.isActive ? 'Inactive' : 'Active'}
                        </span>
                    </Button>
                </>,
                name: 'Status',
                ignoreRowClick: true,
                maxWidth: '110px'
            },
            {
                cell: (row) => <>
                    {/* <Button onClick={() => props?.history.push(`/product/` + row?._id)}
                title={"View"}
                style={{ backgroundColor: Utils.themeColor, marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}><span className="mdi mdi-eye" style={{ fontSize: 20 }}></span></Button> */}
                    <button onClick={() => handleUpdateProj(row)}
                        title={"Edit"} //style={{ border: 'none', backgroundColor: Utils.themeColor,color:'white'}}
                        style={{ marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none', backgroundColor: Utils.themeColor, color: 'white', borderRadius: 3 }}><span className="ri-edit-fill" style={{ fontSize: 15 }}></span></button>
                    <button onClick={() => handleDeleteProj(row)}
                        title={"Delete"}
                        style={{ marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none', backgroundColor: Utils.themeColor, color: 'white', borderRadius: 3 }}><span className="ri-delete-bin-6-line" style={{ fontSize: 15 }}></span></button>
                </>,
                name: 'Action',
                ignoreRowClick: true,
                maxWidth: '130px'
            },
        ])
    }, [data]);

    useEffect(() => {
        if (!isAdd)
            setFile(null);
    }, [isAdd]);

    const getData = () => {
        if (!loading) {
            setLoading(true);
            get("course/coupon", { token: user?.token })
                .then(res => {
                    setLoading(false);
                    if (res?.statusCode == 200) {
                        setData(res?.data);
                    }
                })
                .catch(err => {
                    setLoading(false);
                    toast.error("Something Went Wrong!");
                })
        }
    }

    const handleValidSubmit = async (e, v) => {
        if (!loading) {
            setLoading(true);
            let body = {
                ...v,
                token: user?.token
            }
            if (currentData?._id) {
                body = { ...body, couponId: currentData?._id }
                put("course/coupon", body)
                    .then(res => {
                        setLoading(false);
                        if (res?.statusCode == 200) {
                            toast.success(res?.message)
                            setIsAdd(false);
                            getData()
                        } else
                            toast.error("" + res?.error);
                    })
                    .catch(err => {
                        setLoading(false);
                        console.error("error while updating data", err);
                        toast.error("Something Went Wrong!");
                    })
            } else {
                post("course/coupon", body)
                    .then(res => {
                        setLoading(false);
                        if (res?.statusCode == 200) {
                            toast.success(res?.message);
                            setIsAdd(false);
                            getData();
                        } else {
                            toast.error(res?.error);
                        }
                    })
                    .catch(err => {
                        setLoading(false);
                        console.error("Error while adding city", err);
                        toast.error("Something Went Wrong!");
                    })
            }
        }
    }

    const handleUpdateStatus = (row) => {
        setCurrentData({ ...row, actionType: "Status" });
        setConfirm(true);
    }

    const handleUpdateProj = (row) => {
        setCurrentData(row);
        setIsAdd(true);
    }

    const handleDeleteProj = (row) => {
        setCurrentData({ ...row, actionType: "Delete" });
        setConfirm(true);
    }

    const onConfirm = () => {
        if (!loading) {
            setLoading(true);
            let body = {
                couponId: currentData?._id,
                token: user?.token
            }
            if (currentData?.actionType == 'Status') {
                body = { ...body, isActive: !currentData?.isActive }
                put("course/coupon", body)
                    .then(res => {
                        setLoading(false);
                        if (res?.statusCode == 200) {
                            setConfirm(false);
                            toast.success(res?.message)
                            getData()
                        } else
                            toast.error("" + res?.error);
                    })
                    .catch(err => {
                        setLoading(false);
                        console.error("error while updating data", err);
                        toast.error("Something Went Wrong!");
                    })
            }
            if (currentData?.actionType == 'Delete') {
                del("course/coupon", body)
                    .then(res => {
                        setLoading(false);
                        if (res?.statusCode == 200) {
                            setConfirm(false);
                            toast.success(res?.message)
                            getData()
                        } else
                            toast.error("" + res?.error);
                    })
                    .catch(err => {
                        setLoading(false);
                        console.error("error while updating data", err);
                        toast.error("Something Went Wrong!");
                    })
            }
        }
    }

    const handleImgChange = (file) => {
        setFile(URL.createObjectURL(file));
    }

    const urltoFile = (url, filename, mimeType) => {
        return (fetch(url)
            .then(function (res) { return res.arrayBuffer(); })
            .then(function (buf) { return new File([buf], filename, { type: mimeType }); })
        );
    }

    return (
        <React.Fragment>
            <Header />
            <ConfirmModal
                show={confirm}
                onConfirm={onConfirm}
                onCloseClick={() => setConfirm(false)}
                data={currentData}
            />
            {
                loading == true && <Loader />
            }
            <div className="main main-app p-3 p-lg-4">
                <Card>
                    <Row className='mb-4'>
                        <Col md={11}>
                            <CardBody>
                                <CardTitle><b>Course Coupons</b></CardTitle>
                                {/* <CardSubtitle>Categories you have added before</CardSubtitle> */}
                            </CardBody>
                        </Col>
                        <Col md={1}>
                            <div className='action-btn'>
                                <button type="button" style={{ border: 'none', backgroundColor: Utils.themeColor, color: 'white' }} className="btn-add" onClick={() => { setCurrentData(null); setIsAdd(true) }}><i className={'ri-add-fill'} /></button>
                            </div>
                        </Col>
                    </Row>
                    <DataTable
                        columns={columns}
                        data={data}
                        pagination
                        conditionalRowStyles={[{
                            when: row => row?.style,
                            style: row => ({ width: row?.style?.width }),
                        },
                        ]}
                        customStyles={{
                            headCells: {
                                style: {
                                    color: 'black',
                                    fontWeight: 'bold',
                                    fontSize: 15,
                                    width: 0
                                },
                            },
                            cells: {
                                style: {
                                    width: 0
                                }
                            }
                        }}
                    />
                </Card>
                <Footer />
            </div>
            <Modal isOpen={isAdd} centered>
                <ModalBody>
                    <Card>
                        <CardBody>
                            <CardTitle className='mb-4'><b>{currentData?._id ? 'Update Coupon' : 'Add New Coupon'}</b></CardTitle>
                            <AvForm onValidSubmit={handleValidSubmit}>
                                <Row>
                                    <Col md={60}>
                                        <div className='mb-4'>
                                            <AvField
                                                name="name"
                                                label="Name*"
                                                placeholder="Enter coupon name"
                                                value={currentData?.name}
                                                required
                                            />
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div className='mb-4'>
                                            <AvField
                                                name="code"
                                                label="Code*"
                                                placeholder="Enter cupon code"
                                                value={currentData?.code}
                                                required
                                            />
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div className='mb-4'>
                                            <AvField
                                                name="discount"
                                                placeholder="Enter discount"
                                                label="Discount*"
                                                value={currentData?.discount}
                                                required
                                            />
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div className='mb-4'>
                                            <AvField
                                                name="startdate"
                                                label="Start Date*"
                                                value={currentData?.startdate}
                                                type="date"
                                                required
                                            />
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div className='mb-4'>
                                            <AvField
                                                name="enddate"
                                                label="End Date*"
                                                value={currentData?.enddate}
                                                type="date"
                                                required
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6}>
                                        <button type="reset" style={{ border: 'none', backgroundColor: Utils.themeColor, color: 'white' }} onClick={() => setIsAdd(false)} className="btn btn-danger w-100 waves-effect waves-light">Cancel</button>
                                    </Col>
                                    <Col md={6}>
                                        <button type="submit" style={{ border: 'none', backgroundColor: Utils.themeColor, color: 'white' }} className="btn btn-danger w-100 waves-effect waves-light" >{currentData?._id ? 'Update' : 'Add'}</button>
                                    </Col>
                                </Row>
                            </AvForm>
                        </CardBody>
                    </Card>
                </ModalBody>
            </Modal>
        </React.Fragment>
    )
}

export default AddService;