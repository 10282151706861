import React, { useEffect, useState } from "react";
// import { Row } from "react-bootstrap";
import Footer from "../layouts/Footer";
import Header from "../layouts/Header";
import { toast } from 'react-toastify';
import { get } from '../helper/api_helper';
import { useSelector } from "react-redux";
import Loader from "../layouts/Loader";
import { Row, Col, CardBody, Card, Progress } from "reactstrap"

export default function FinanceMonitoring() {
  const user = useSelector(state => state.user);
  const currentSkin = (localStorage.getItem('skin-mode')) ? 'dark' : '';
  const [skin, setSkin] = useState(currentSkin);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(undefined);

  const switchSkin = (skin) => {
    if (skin === 'dark') {
      const btnWhite = document.getElementsByClassName('btn-white');

      for (const btn of btnWhite) {
        btn.classList.add('btn-outline-primary');
        btn.classList.remove('btn-white');
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName('btn-outline-primary');

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove('btn-outline-primary');
        btn.classList.add('btn-white');
      }
    }
  };

  switchSkin(skin);

  useEffect(() => {
    switchSkin(skin);
  }, [skin]);

  useEffect(() => {
    if (user?.token) {
      getData();
    }
  }, [user]);

  const getData = () => {
    if (!loading) {
      setLoading(true);
      get("stat", { token: user?.token })
        .then(res => {
          setLoading(false);
          if (res?.statusCode == 200) {
            setData(res?.data);

          }
        })
        .catch(err => {
          setLoading(false);
          toast.error("Something Went Wrong!");
        })
    }
  }

  return (
    <React.Fragment>
      <Header onSkin={setSkin} />
      <div className="main main-app p-3 p-lg-4">
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              {/* <li className="breadcrumb-item"><Link to="#">Dashboard</Link></li> */}
            </ol>
            <h4 className="main-title mb-0">Dashboard</h4>
          </div>
        </div>
        {/* {
          loading == true && <Loader />
        } */}
        <Row>
          {/* <div className="row">
            <div className="col-sm-3">
              <div className="card box1">
                <div className="card-body">
                  <center> <h5 className="card-title">Subscribers<center> <h2 className="box">{data?.pet}</h2></center></h5></center>
                </div>
              </div>
            </div>
            <div className="col-sm-3">
              <div className="card box1">
                <div className="card-body">
                  <center>   <h5 className="card-title">Registered User  <center> <h2 className="box">{data?.user}</h2></center></h5></center>
                </div>
              </div>
            </div>
          </div> */}
          <Col lg={4}>
            <Card style={{ height: '60%', background: '#6861CE' }}>
              <CardBody>
                <div className="d-flex align-items-start">
                  <div className="avatar-sm font-size-20 me-3">
                    <span className="avatar-title bg-white text-black rounded">
                      <i className="mdi mdi-account-outline"></i>
                    </span>
                  </div>
                  <div className="flex-1">
                    <div className="font-size-17 mt-2 text-white"><h4><i className={"fa fa-user"} style={{ fontSize: 20 }}></i>&nbsp; Registerd Users</h4> </div>
                  </div>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around' }}>
                  <p to={{ pathname: "/users", state: { type: "Registered", time: "Week" } }} style={{ textDecoderation: 'none' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                      <b className="mb-3" style={{ fontSize: 20, color: 'white' }}>{data?.user}</b>
                    </div>
                  </p>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col lg={4}>
            <Card style={{ height: '60%', background: '#31CE36' }}>
              <CardBody>
                <div className="d-flex align-items-start">
                  <div className="avatar-sm font-size-20 me-3">
                    <span className="avatar-title bg-white text-black rounded">
                      <i className="mdi mdi-account-outline"></i>
                    </span>
                  </div>
                  <div className="flex-1">
                    <div className="font-size-17 mt-2 text-white"><h4><i className={"fa fa-book"} style={{ fontSize: 20 }}></i>&nbsp; Courses</h4> </div>
                  </div>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around' }}>
                  <p to={{ pathname: "/users", state: { type: "Registered", time: "Week" } }} style={{ textDecoderation: 'none' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                      <b className="mb-3" style={{ fontSize: 20, color: 'white' }}>{data?.course}</b>
                    </div>
                  </p>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col lg={4}>
            <Card style={{ height: '60%', background: '#F25961' }}>
              <CardBody>
                <div className="d-flex align-items-start">
                  <div className="avatar-sm font-size-20 me-3">
                    <span className="avatar-title bg-white text-black rounded">
                      <i className="mdi mdi-account-outline"></i>
                    </span>
                  </div>
                  <div className="flex-1">
                    <div className="font-size-17 mt-2 text-white"><h4><i className={"fa fa-sitemap"} style={{ fontSize: 20 }}></i>&nbsp; Course Category</h4> </div>
                  </div>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around' }}>
                  <p to={{ pathname: "/users", state: { type: "Registered", time: "Week" } }} style={{ textDecoderation: 'none' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                      <b className="mb-5" style={{ fontSize: 20, color: 'white' }}>{data?.category}</b>
                    </div>
                  </p>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col lg={4}>
            <Card style={{ height: '60%', background: '#1572E8' }}>
              <CardBody>
                <div className="d-flex align-items-start">
                  <div className="avatar-sm font-size-20 me-3">
                    <span className="avatar-title bg-white text-black rounded">
                      <i className="mdi mdi-account-outline"></i>
                    </span>
                  </div>
                  <div className="flex-1">
                    <div className="font-size-17 mt-2 text-white"><h4><i className={"fa fa-file-text"} style={{ fontSize: 20 }}></i>&nbsp; Course Enrolments</h4> </div>
                  </div>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around' }}>
                  <p to={{ pathname: "/users", state: { type: "Registered", time: "Week" } }} style={{ textDecoderation: 'none' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                      <b className="mb-5" style={{ fontSize: 20, color: 'white' }}>{data?.enrolment}</b>
                    </div>
                  </p>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col lg={4}>
            <Card style={{ height: '60%', background: 'rgb(107, 19, 15)' }}>
              <CardBody>
                <div className="d-flex align-items-start">
                  <div className="avatar-sm font-size-20 me-3">
                    <span className="avatar-title bg-white text-black rounded">
                      <i className="mdi mdi-account-outline"></i>
                    </span>
                  </div>
                  <div className="flex-1">
                    <div className="font-size-17 mt-2 text-white"><h4><i className={"fa fa-file-text"} style={{ fontSize: 20 }}></i>&nbsp; Teams</h4> </div>
                  </div>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around' }}>
                  <p to={{ pathname: "/users", state: { type: "Registered", time: "Week" } }} style={{ textDecoderation: 'none' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                      <b className="mb-5" style={{ fontSize: 20, color: 'white' }}>{data?.team}</b>
                    </div>
                  </p>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col lg={4}>
            <Card style={{ height: '60%', background: '#FFAD46' }}>
              <CardBody>
                <div className="d-flex align-items-start">
                  <div className="avatar-sm font-size-20 me-3">
                    <span className="avatar-title bg-white text-black rounded">
                      <i className="mdi mdi-account-outline"></i>
                    </span>
                  </div>
                  <div className="flex-1">
                    <div className="font-size-17 mt-2 text-white"><h4><i className={"fa fa-users"} style={{ fontSize: 20 }}></i>&nbsp; Subscribers</h4> </div>
                  </div>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around' }}>
                  <p to={{ pathname: "/users", state: { type: "Registered", time: "Week" } }} style={{ textDecoderation: 'none' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                      <b className="mb-5" style={{ fontSize: 20, color: 'white' }}>{data?.subscribe}</b>
                    </div>
                  </p>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col lg={4}>
            <Card style={{ height: '60%', background: '#6861CE' }}>
              <CardBody>
                <div className="d-flex align-items-start">
                  <div className="avatar-sm font-size-20 me-3">
                    <span className="avatar-title bg-white text-black rounded">
                      <i className="mdi mdi-account-outline"></i>
                    </span>
                  </div>
                  <div className="flex-1">
                    <div className="font-size-17 mt-2 text-white"><h4><i className={"fa fa-users"} style={{ fontSize: 20 }}></i>&nbsp; Contact</h4> </div>
                  </div>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around' }}>
                  <p to={{ pathname: "/users", state: { type: "Registered", time: "Week" } }} style={{ textDecoderation: 'none' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                      <b className="mb-5" style={{ fontSize: 20, color: 'white' }}>{data?.contact}</b>
                    </div>
                  </p>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Footer />
      </div>
    </React.Fragment>
  );
}
