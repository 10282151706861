import React, { useEffect, useState, useRef } from 'react'
import Header from '../../layouts/Header'
import { Card, CardBody, CardTitle, Row, Col, Modal, ModalBody, Label } from 'reactstrap'
import { Button } from 'react-bootstrap';
import { AvForm, AvField } from "availity-reactstrap-validation";
import { del, get, post, put, upload } from '../../helper/api_helper';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import DataTable from 'react-data-table-component';
import ConfirmModal from '../../components/ConfirmModal';
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import Footer from '../../layouts/Footer';
import * as Utils from "../../Utils";
import Loader from '../../layouts/Loader';
import moment from 'moment';



function AddService() {

    const user = useSelector(state => state.user);
    const [isAdd, setIsAdd] = useState(false);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [cats, setCats] = useState([]);
    const [columns, setColumns] = useState([]);
    const [currentData, setCurrentData] = useState(null);
    const [confirm, setConfirm] = useState(false);
    const [file, setFile] = useState(null);
    const [fileType, setFileType] = useState("thumb");
    const [thumb, setThumb] = useState(null);
    const [image, setImage] = useState(null);
    const [cropperModal, setCropperModal] = useState(false);
    const cropperRef = useRef();

    useEffect(() => {
        if (user?.token) {
            getData();
        }
    }, [user]);

    useEffect(() => {
        setColumns([
            {
                name: "Image",
                cell: (row) => <>
                    <img src={row?.image} style={{ width: 70, height: 70, padding: 10 }} />
                </>,
                maxWidth: '200px',
                minWidth: '100px'
            },
            {
                name: 'Title',
                selector: row => row.title,
            },
            {
                name: 'Product',
                selector: row => row.product,
            },
            {
                name: 'Tender',
                selector: row => row.tender,
            },
            {
                name: 'City',
                selector: row => row.city,
            },
            {
                name: 'Date',
                selector: row => moment(row?.updatedAt).format("DD-MMM-YYYY"),
                minWidth: '130px'
            },
            {
                cell: (row) => <>
                    <Button onClick={() => handleUpdateStatus(row)}
                        title={row?.isActive ? "Inactive" : "Active"}
                        className={`btn_status ${row?.isActive && 'active'}`}
                    >
                        <span className="text-white" style={{}}>
                            {!row?.isActive ? 'Inactive' : 'Active'}
                        </span>
                    </Button>
                </>,
                name: 'Status',
                ignoreRowClick: true,
                maxWidth: '110px'
            },
            {
                cell: (row) => <>
                    {/* <Button onClick={() => props?.history.push(`/product/` + row?._id)}
                title={"View"}
                style={{ backgroundColor: Utils.themeColor, marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}><span className="mdi mdi-eye" style={{ fontSize: 20 }}></span></Button> */}
                    <Button onClick={() => handleUpdateProj(row)}
                        title={"Edit"} variant="danger"
                        style={{ marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}><span className="ri-edit-fill" style={{ fontSize: 15 }}></span></Button>
                    <Button onClick={() => handleDeleteProj(row)}
                        title={"Delete"} variant="danger"
                        style={{ marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8, border: 'none' }}><span className="ri-delete-bin-6-line" style={{ fontSize: 15 }}></span></Button>
                </>,
                name: 'Action',
                ignoreRowClick: true,
                maxWidth: '130px'
            },
        ]);
    }, [data]);

    useEffect(() => {
        if (!isAdd) {
            setFile(null);
            setImage(null);
        }
    }, [isAdd]);

    const getData = () => {
        if (!loading) {
            setLoading(true);
            get("offer", { token: user?.token })
                .then(res => {
                    setLoading(false);
                    if (res?.statusCode == 200) {
                        setData(res?.data);
                    }
                })
                .catch(err => {
                    setLoading(false);
                    toast.error("Something Went Wrong!");
                })
        }
    }

    const handleUpdateStatus = (row) => {
        setCurrentData({ ...row, actionType: "Status" });
        setConfirm(true);
    }

    const handleUpdateProj = (row) => {
        setCurrentData(row);
        setThumb(row?.categoryPic);
        setImage(row?.image);
        setIsAdd(true);
    }

    const handleDeleteProj = (row) => {
        setCurrentData({ ...row, actionType: "Delete" });
        setConfirm(true);
    }

    const onConfirm = () => {
        if (!loading) {
            setLoading(true);
            let body = {
                offerId: currentData?._id,
                token: user?.token
            }
            if (currentData?.actionType == 'Status') {
                body = { ...body, isActive: !currentData?.isActive }
                put("offer", body)
                    .then(res => {
                        setLoading(false);
                        if (res?.statusCode == 200) {
                            toast.success(res?.message);
                            setConfirm(false);
                            getData()
                        } else
                            toast.error("" + res?.error);
                    })
                    .catch(err => {
                        setLoading(false);
                        console.error("error while updating data", err);
                        toast.error("Something Went Wrong!");
                    })
            }
            if (currentData?.actionType == 'Delete') {
                del("offer", body)
                    .then(res => {
                        setLoading(false);
                        if (res?.statusCode == 200) {
                            setConfirm(false);
                            toast.success(res?.message)
                            getData()
                        } else
                            toast.error("" + res?.error);
                    })
                    .catch(err => {
                        setLoading(false);
                        console.error("error while updating data", err);
                        toast.error("Something Went Wrong!");
                    })
            }
        }
    }

    const urltoFile = (url, filename, mimeType) => {
        return (fetch(url)
            .then(function (res) { return res.arrayBuffer(); })
            .then(function (buf) { return new File([buf], filename, { type: mimeType }); })
        );
    }

    const handleValidSubmit = async (e, v) => {
        if (!loading) {
            setLoading(true);
            let body = {
                ...v,
                token: user?.token
            }
            console.log("file to check", file);
            if (!image) {
                toast.error("Please select a Client Location Image!");
                return;
            }
            if (image?.substring(0, 4) == 'data') {
                const obj = await urltoFile(image, new Date().getTime() + '.png', 'image/png');
                const form = new FormData();
                form.append("image", obj);
                const uploadRes = await upload("offer/image_upload", form);
                if (uploadRes?.statusCode == 200)
                    body = { ...body, image: uploadRes?.data };
            }
            if (currentData?._id) {
                body = { ...body, offerId: currentData?._id }
                put("offer", body)
                    .then(res => {
                        setLoading(false);
                        if (res?.statusCode == 200) {
                            setIsAdd(false);
                            toast.success(res?.message)
                            getData()

                        } else
                            toast.error("" + res?.error);
                    })
                    .catch(err => {
                        setLoading(false);
                        console.error("error while updating data", err);
                        toast.error("Something Went Wrong!");
                    })
            } else {
                post("offer", body)
                    .then(res => {
                        setLoading(false);
                        if (res?.statusCode == 200) {
                            toast.success(res?.message);
                            setIsAdd(false);
                            getData();
                        } else {
                            toast.error(res?.error);
                        }
                    })
                    .catch(err => {
                        setLoading(false);
                        console.error("Error while adding city", err);
                        toast.error("Something Went Wrong!");
                    })
            }
        }
    }

    const handleImgChange = (file, type) => {
        setFileType(type);
        setFile(URL.createObjectURL(file));
        setCropperModal(true);
    }

    const cropImage = () => {
        setLoading(true);
        const fileData = cropperRef?.current?.cropper?.getCroppedCanvas().toDataURL();
        if (fileType == "thumb")
            setThumb(fileData);
        else
            setImage(fileData);
        setCropperModal(false);
        setLoading(false);
    }

    return (
        <React.Fragment>
            <Header />
            <ConfirmModal
                show={confirm}
                onConfirm={onConfirm}
                onCloseClick={() => setConfirm(false)}
                data={currentData}
            />
            {
                loading == true && <Loader />
            }
            <div className="main main-app p-3 p-lg-4">

                <Card>
                    <Row className='mb-4'>
                        <Col md={11}>
                            <CardBody>
                                <CardTitle><b>Special Offers</b></CardTitle>
                            </CardBody>
                        </Col>
                        <Col md={1}>
                            <div className='action-btn'>
                                <button type="button" style={{ border: 'none', backgroundColor: Utils.themeColor }} className="btn btn-danger" onClick={() => { setCurrentData(null); setIsAdd(true) }}>Add <i className={'ri-add-fill'} /></button>
                            </div>
                        </Col>
                    </Row>
                    <DataTable
                        columns={columns}
                        data={data}
                        pagination
                        conditionalRowStyles={[{
                            when: row => row?.style,
                            style: row => ({ width: row?.style?.width }),
                        },
                        ]}
                        customStyles={{
                            headCells: {
                                style: {
                                    color: 'black',
                                    fontWeight: 'bold',
                                    fontSize: 15,
                                    width: 0
                                },
                            },
                            cells: {
                                style: {
                                    width: 0
                                }
                            }
                        }}
                    />
                </Card>
                <Footer />
            </div>
            <Modal isOpen={cropperModal} centered={true}>
                <ModalBody>
                    <h5 className="text-black font-size-20">Crop Image</h5>
                    <div className="p-2">
                        <AvForm
                            className="form-horizontal"
                            onValidSubmit={(e, v) => {
                                cropImage()
                            }}
                        >
                            <div className="mb-3 mt-2">
                                <Cropper
                                    style={{ height: 200, width: 400 }}
                                    aspectRatio={1 / 1}
                                    preview=".img-preview"
                                    guides={true}
                                    src={file}
                                    ref={cropperRef}
                                />
                            </div>
                            <div className="mt-4">
                                <Row>
                                    <Col md={6}>
                                        <button
                                            className="btn btn-danger w-100 waves-effect waves-light"
                                            style={{ border: 'none', backgroundColor: Utils.themeColor }}
                                            onClick={() => { setCropperModal(false); setFile(null) }}
                                            type="reset"
                                        >
                                            Cancel
                                        </button>
                                    </Col>
                                    <Col md={6}>
                                        <button
                                            className="btn btn-danger w-100 waves-effect waves-light"
                                            type="submit"
                                            style={{ border: 'none', backgroundColor: Utils.themeColor }}
                                        >
                                            Submit
                                        </button>
                                    </Col>
                                </Row>
                            </div>
                        </AvForm>
                    </div>
                </ModalBody>
            </Modal>
            <Modal isOpen={isAdd} centered>
                <ModalBody>
                    <Card>
                        <CardBody>
                            <CardTitle className='mb-4'><b>{currentData?._id ? 'Update Offer' : 'Add New Offer'}</b></CardTitle>
                            <AvForm onValidSubmit={handleValidSubmit}>
                                <dataLoader />
                                <Label>Image</Label>
                                {image ?
                                    <div className='img-sqr-100'>
                                        <img src={image} style={{ width: '100%', height: '100%' }} />
                                        <div className='btn_cross'>
                                            <Button type="button" variant="danger" className="btn-add" onClick={() => { setImage(null); setFile(null); }}><i className={'fa fa-close'} /></Button>
                                        </div>
                                    </div>
                                    :
                                    <Row>
                                        <Col md={12}>
                                            <div className='mb-4'>
                                                <AvField
                                                    name="catImage"
                                                    required
                                                    type="file"
                                                    onChange={(e) => handleImgChange(e.target.files[0], "image")}
                                                >
                                                </AvField>
                                            </div>
                                        </Col>
                                    </Row>
                                }
                                <Row>
                                    <Col md={6}>
                                        <div className='mb-2 mt-2'>
                                            <AvField
                                                name="title"
                                                label="Title"
                                                placeholder="Enter title"
                                                value={currentData?.title}
                                                required
                                            />
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div className='mb-2 mt-2'>
                                            <AvField
                                                name="tender"
                                                label="Tender"
                                                placeholder="Enter tender.."
                                                value={currentData?.tender}
                                            />
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div className='mb-2 mt-2'>
                                            <AvField
                                                name="city"
                                                label="City"
                                                placeholder="Enter city.."
                                                value={currentData?.city}
                                            />
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div className='mb-2 mt-2'>
                                            <AvField
                                                name="product"
                                                label="Product"
                                                placeholder="Enter product.."
                                                value={currentData?.product}
                                                required
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6}>
                                        <button
                                            className="btn btn-danger w-100 waves-effect waves-light"
                                            style={{ border: 'none', backgroundColor: Utils.themeColor }} onClick={() => setIsAdd(false)} type="reset">  Cancel
                                        </button>
                                    </Col>
                                    <Col md={6}>
                                        <button
                                            className="btn btn-danger w-100 waves-effect waves-light"
                                            style={{ border: 'none', backgroundColor: Utils.themeColor }} type="submit" > {currentData?._id ? 'Update' : 'Add'}
                                        </button>
                                    </Col>
                                </Row>
                            </AvForm>
                        </CardBody>
                    </Card>
                </ModalBody>
            </Modal>
        </React.Fragment>
    )
}

export default AddService;