import React from "react";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <div className="main-footer">
      <span>&copy; 2024. Unnati Skils Course  . All Rights Reserved.</span>
      <span>Design and crafted by: <Link to="#" target="_blank"></Link></span>
    </div>
  )
}