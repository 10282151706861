import React, { useEffect, useState, useRef } from 'react'
import Header from '../../layouts/Header'
import { Card, CardBody, CardSubtitle, CardTitle, Row, Col, Modal, ModalBody } from 'reactstrap'
import { get } from '../../helper/api_helper';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import DataTable from 'react-data-table-component';
import Footer from '../../layouts/Footer';
import Loader from '../../layouts/Loader';
import moment from "moment";

function Contact() {

    const user = useSelector(state => state.user);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [columns, setColumns] = useState([]);

    useEffect(() => {
        if (user?.token) {
            getData();
        }
    }, [user]);

    useEffect(() => {
        setColumns([
            {
                name: 'Sl.No',
                cell: (row, index) => <>
                    <span>{index + 1}</span>
                </>,
                minWidth: '80px'

            },
            {
                name: 'Name',
                cell: (row, index) => <>
                    <p>{row?.name}</p>
                </>,

            },
            {
                name: 'Email',
                cell: (row, index) => <>
                    <p>{row?.email}</p>
                </>,

            },
            {
                name: 'Mobile',
                cell: (row, index) => <>
                    <p>{row?.mobile}</p>
                </>,

            },
            {
                name: 'Created At',
                selector: row => moment(row?.updatedAt).format("DD-MMM-YYYY"),
                minWidth: '170px'
            },
        ])
    }, [data]);

    const getData = () => {
        if (!loading) {
            setLoading(true);
            get("enrolment", { token: user?.token })
                .then(res => {
                    setLoading(false);
                    if (res?.statusCode == 200) {
                        setData(res?.data);
                    }
                })
                .catch(err => {
                    setLoading(false);
                    toast.error("Something Went Wrong!");
                })
        }
    }



    return (
        <React.Fragment>
            <Header />
            {
                loading == true && <Loader />
            }

            <div className="main main-app p-3 p-lg-4">
                <Card>
                    <Row className='mb-4'>
                        <Col md={11}>
                            <CardBody>
                                <CardTitle><b>User Enrolments</b></CardTitle>
                            </CardBody>
                        </Col>
                    </Row>
                    <DataTable
                        columns={columns}
                        data={data}
                        pagination
                        customStyles={{
                            headCells: {
                                style: {
                                    color: 'black',
                                    fontWeight: 'bold',
                                    fontSize: 15,
                                    width: 0
                                },
                            },
                            cells: {
                                style: {
                                    width: 0
                                }
                            }
                        }}
                    />
                </Card>
                <Footer />
            </div>

        </React.Fragment>
    )
}


export default Contact;